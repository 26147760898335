// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  height: 100%;
  width: 100%;
}

main {
  padding-top: 120px;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

@media (max-width: 768px) {
  main {
    padding-top: 250px;
    padding-left: 2.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Layout.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;AACvB;;AAEA;EACE;IACE,kBAAkB;IAClB,oBAAoB;EACtB;AACF","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n}\n\nmain {\n  padding-top: 120px;\n  padding-left: 2.5rem;\n  padding-right: 2.5rem;\n}\n\n@media (max-width: 768px) {\n  main {\n    padding-top: 250px;\n    padding-left: 2.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
