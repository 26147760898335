import { motion } from "framer-motion";
import "./Blog.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ArticleCard from "./ArticleCard";

const Blog = ({ recent }) => {
  const [titles, setTitles] = useState([]);

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}api/articles?fields[0]=title&fields[1]=category&fields[2]=createdAt`
      )
      .then((response) => {
        let titlesByDate = response.data.data.sort((a, b) => {
          let dateA = new Date(a.attributes.createdAt);
          let dateB = new Date(b.attributes.createdAt);

          return dateB - dateA;
        });
        if (recent) {
          titlesByDate = titlesByDate.slice(0, 3);
        }
        setTitles([...new Set(titlesByDate)]);
      })
      .catch((error) => {
        console.error("Error fetching the articles:", error);
      });
  }, []);

  function formatDate(date) {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  return (
    <motion.div
      className="title-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeOut", duration: 1 }}
    >
      <div className="data-container">
        {titles.map((title) => (
          <Link
            className="article-link"
            key={title.id}
            to={`/article/${title.id}`}
          >
            <ArticleCard
              date={formatDate(new Date(title.attributes.createdAt))}
              title={title.attributes.title}
              category={title.attributes.category}
            />
          </Link>
        ))}
      </div>
    </motion.div>
  );
};

export default Blog;
