// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.about {
  color: #00ffc2;
  font-weight: 350;
  letter-spacing: 0.1rem;
  margin-bottom: 20px; /* Add space between the heading and the articles */
}

.about-image {
  max-width: 100%; /* Ensure the image scales with its container */
  height: auto; /* Maintain aspect ratio */
  width: 50%; /* Adjust this value as needed */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center the image within its container */
  transform: rotate(90deg);
}

.text-container {
  max-width: 50%;
}

.about-container {
  display: flex;
  flex-direction: row;
  height: 50vh;
}

.page-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 75vh; /* Full viewport height */
}
`, "",{"version":3,"sources":["webpack://./src/pages/About.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,gBAAgB;EAChB,sBAAsB;EACtB,mBAAmB,EAAE,mDAAmD;AAC1E;;AAEA;EACE,eAAe,EAAE,+CAA+C;EAChE,YAAY,EAAE,0BAA0B;EACxC,UAAU,EAAE,gCAAgC;EAC5C,cAAc,EAAE,8BAA8B;EAC9C,cAAc,EAAE,0CAA0C;EAC1D,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,aAAa;EACb,uBAAuB,EAAE,wBAAwB;EACjD,mBAAmB,EAAE,sBAAsB;EAC3C,YAAY,EAAE,yBAAyB;AACzC","sourcesContent":[".about {\n  color: #00ffc2;\n  font-weight: 350;\n  letter-spacing: 0.1rem;\n  margin-bottom: 20px; /* Add space between the heading and the articles */\n}\n\n.about-image {\n  max-width: 100%; /* Ensure the image scales with its container */\n  height: auto; /* Maintain aspect ratio */\n  width: 50%; /* Adjust this value as needed */\n  display: block; /* Remove any inline spacing */\n  margin: 0 auto; /* Center the image within its container */\n  transform: rotate(90deg);\n}\n\n.text-container {\n  max-width: 50%;\n}\n\n.about-container {\n  display: flex;\n  flex-direction: row;\n  height: 50vh;\n}\n\n.page-container {\n  display: flex;\n  justify-content: center; /* Center horizontally */\n  align-items: center; /* Center vertically */\n  height: 75vh; /* Full viewport height */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
