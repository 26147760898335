import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  const [animated, setAnimated] = useState(false);
  const [initialRender, setInitialRender] = useState(true);

  useEffect(() => {
    const hasAnimated = sessionStorage.getItem("animated");
    if (!hasAnimated) {
      setAnimated(true);
      sessionStorage.setItem("animated", "true");
    }
    setInitialRender(false);
  }, []);

  const listVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        staggerChildren: 0.1,
        duration: 2.5,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 0 },
    visible: { opacity: 1, y: 0 },
  };

  if (initialRender) return null;

  return (
    <header className="site-header">
      <div className="container">
        {animated ? (
          <motion.nav
            initial="hidden"
            animate="visible"
            variants={listVariants}
          >
            <ul>
              <motion.li variants={itemVariants}>
                <Link to="/">
                  <h1>
                    <span>STEFAN</span>
                    <span>BERLIND</span>
                  </h1>
                </Link>
              </motion.li>
              <motion.li variants={itemVariants}>
                <Link to="/">HOME</Link>
              </motion.li>
              <motion.li variants={itemVariants}>
                <Link to="/blog">BLOG</Link>
              </motion.li>
              <motion.li variants={itemVariants}>
                <a
                  href="https://stefanfilebucket.s3.us-east-1.amazonaws.com/stefan_berlind_cv_b30540b2a9.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RESUME
                </a>
              </motion.li>
              <motion.li variants={itemVariants}>
                <Link to="/about">ABOUT</Link>
              </motion.li>
            </ul>
          </motion.nav>
        ) : (
          <nav>
            <ul>
              <li>
                <Link to="/">
                  <h1>
                    <span>STEFAN</span>
                    <span>BERLIND</span>
                  </h1>
                </Link>
              </li>
              <li>
                <Link to="/">HOME</Link>
              </li>
              <li>
                <Link to="/blog">BLOG</Link>
              </li>
              <li>
                <a href="https://stefanfilebucket.s3.us-east-1.amazonaws.com/stefan_berlind_cv_5360de26b9.pdf">
                  RESUME
                </a>
              </li>
              <li>
                <Link to="/about">ABOUT</Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
