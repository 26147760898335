// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.markdown-container {
  width: 75%;
  max-width: 100%; /* Ensure content does not exceed 65% of the viewport */
  margin: 0 auto; /* Center the container horizontally */
  line-height: 1.6;
}

.markdown-container img {
  max-width: 75%; /* Ensure images scale within their container */
  max-height: 450px; /* Set maximum height for images */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Remove any inline spacing */
  margin: 0 auto; /* Center images within their container */
}

pre {
  /* Light background for code blocks */
  overflow-x: auto;
  max-width: 65%; /* Ensure it doesn't overflow the container */
  font-size: 0.85em; /* Smaller font size for code blocks */
  margin: 0 auto;
}

.markdown-container h2.metadata {
  font-size: 0.8em;
  color: #00ffc2;
}

p code {
  color: rgb(71, 235, 180);
}

p {
  color: white;
  font-weight: 250;
  margin-top: 0;
}

.markdown-container h1.title {
  font-size: 3rem;
  margin-bottom: 0;
}

.markdown-container h1 {
  color: white;
  font-weight: 350;
  margin-top: 0;
}

.markdown-container h2 {
  color: white;
  font-weight: 300;
  margin-top: 0;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Article.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,eAAe,EAAE,uDAAuD;EACxE,cAAc,EAAE,sCAAsC;EACtD,gBAAgB;AAClB;;AAEA;EACE,cAAc,EAAE,+CAA+C;EAC/D,iBAAiB,EAAE,kCAAkC;EACrD,YAAY,EAAE,0BAA0B;EACxC,cAAc,EAAE,8BAA8B;EAC9C,cAAc,EAAE,yCAAyC;AAC3D;;AAEA;EACE,qCAAqC;EACrC,gBAAgB;EAChB,cAAc,EAAE,6CAA6C;EAC7D,iBAAiB,EAAE,sCAAsC;EACzD,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,aAAa;AACf","sourcesContent":[".markdown-container {\n  width: 75%;\n  max-width: 100%; /* Ensure content does not exceed 65% of the viewport */\n  margin: 0 auto; /* Center the container horizontally */\n  line-height: 1.6;\n}\n\n.markdown-container img {\n  max-width: 75%; /* Ensure images scale within their container */\n  max-height: 450px; /* Set maximum height for images */\n  height: auto; /* Maintain aspect ratio */\n  display: block; /* Remove any inline spacing */\n  margin: 0 auto; /* Center images within their container */\n}\n\npre {\n  /* Light background for code blocks */\n  overflow-x: auto;\n  max-width: 65%; /* Ensure it doesn't overflow the container */\n  font-size: 0.85em; /* Smaller font size for code blocks */\n  margin: 0 auto;\n}\n\n.markdown-container h2.metadata {\n  font-size: 0.8em;\n  color: #00ffc2;\n}\n\np code {\n  color: rgb(71, 235, 180);\n}\n\np {\n  color: white;\n  font-weight: 250;\n  margin-top: 0;\n}\n\n.markdown-container h1.title {\n  font-size: 3rem;\n  margin-bottom: 0;\n}\n\n.markdown-container h1 {\n  color: white;\n  font-weight: 350;\n  margin-top: 0;\n}\n\n.markdown-container h2 {\n  color: white;\n  font-weight: 300;\n  margin-top: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
