import "./Home.css";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import RecentContent from "./RecentContent";

const Home = () => {
  return (
    <motion.div
      className="grid-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeOut", duration: 1 }}
    >
      {/* Left Column */}
      <div className="left-column">
        <div className="grid-item">
          <h1 className="title">CYBERSECURITY PROFESSIONAL</h1>
          <p>
            Specializing in reverse engineering, vulnerability research, and
            binary exploitation.
          </p>
        </div>
        <div className="grid-item">
          <h1 className="title">Get to Know Me</h1>
          <p>
            Welcome to my website! My name is Stefan. I am currently pursuing a
            Master's degree in computer science from the University of
            Massachusetts. My focus area is reverse engineering and binary
            exploitation. On this site you can find my resume as well as my
            blog, where I write mainly about reverse engineering and CTFs, but I
            have some other interesting things there as well. If you want to
            learn more about me, click the button below. Otherwise, have fun
            browsing my site!
          </p>
          <div className="learn-more-container">
            <Link to="/about" className="learn-more">
              LEARN MORE
            </Link>
            <motion.a
              href="https://www.linkedin.com/in/stefan-berlind-214baa211/"
              target="_blank"
              rel="noopener noreferrer"
              whileHover={{ scale: 1.2 }} // Scale up on hover
              className="linkedin-link"
            >
              <FontAwesomeIcon icon={faLinkedin} color="white" size="2x" />
            </motion.a>
          </div>
        </div>
      </div>

      {/* Right Column */}
      <div className="right-column">
        <div className="grid-item large-block">
          <h1 className="recent-content">RECENT CONTENT</h1>
          <RecentContent />
        </div>
      </div>
    </motion.div>
  );
};

export default Home;
