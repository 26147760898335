// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title-container {
  display: flex;
  justify-content: center; /* Center the child content horizontally */
  width: 100%; /* Ensure the container takes full width */
  /* Optional: make the container take the full height of the viewport */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.data-container {
  display: flex;
  flex-direction: column;
  align-items: left; /* Align items to the left */
  width: 100%; /* Ensure it takes full width */
  max-width: 800px; /* Optional: set a max-width for better control */
  margin: 0; /* Remove any default margin */
  padding: 0; /* Remove any default padding */
}

.article-link {
  text-decoration: none;
  margin-bottom: 10px; /* Add some space between articles */
}
`, "",{"version":3,"sources":["webpack://./src/pages/Blog.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB,EAAE,0CAA0C;EACnE,WAAW,EAAE,0CAA0C;EACvD,sEAAsE;EACtE,SAAS,EAAE,8BAA8B;EACzC,UAAU,EAAE,+BAA+B;AAC7C;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB,EAAE,4BAA4B;EAC/C,WAAW,EAAE,+BAA+B;EAC5C,gBAAgB,EAAE,iDAAiD;EACnE,SAAS,EAAE,8BAA8B;EACzC,UAAU,EAAE,+BAA+B;AAC7C;;AAEA;EACE,qBAAqB;EACrB,mBAAmB,EAAE,oCAAoC;AAC3D","sourcesContent":[".title-container {\n  display: flex;\n  justify-content: center; /* Center the child content horizontally */\n  width: 100%; /* Ensure the container takes full width */\n  /* Optional: make the container take the full height of the viewport */\n  margin: 0; /* Remove any default margin */\n  padding: 0; /* Remove any default padding */\n}\n\n.data-container {\n  display: flex;\n  flex-direction: column;\n  align-items: left; /* Align items to the left */\n  width: 100%; /* Ensure it takes full width */\n  max-width: 800px; /* Optional: set a max-width for better control */\n  margin: 0; /* Remove any default margin */\n  padding: 0; /* Remove any default padding */\n}\n\n.article-link {\n  text-decoration: none;\n  margin-bottom: 10px; /* Add some space between articles */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
