import "./ArticleCard.css";
import { motion } from "framer-motion";

const ArticleCard = ({ title, date, category }) => {
  return (
    <motion.div
      className="article-card"
      whileHover={{ color: "#00ffc2", scale: 1.02 }}
      transition={{ duration: 0.3 }}
    >
      <h1 className="article-title">{title}</h1>
      <h2 className="metadata">
        {date} | {category}
      </h2>
    </motion.div>
  );
};

export default ArticleCard;
