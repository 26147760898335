import "./About.css";
import { motion } from "framer-motion";

const About = () => {
  return (
    <motion.div
      className="page-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ ease: "easeOut", duration: 1 }}
    >
      <div className="about-container">
        <div className="image-container">
          <img
            className="about-image"
            src="https://stefanfilebucket.s3.us-east-1.amazonaws.com/IMG_8193_84c4504575.jpg"
            alt="Stefan"
          />
        </div>
        <div className="text-container">
          <h1 className="about">ABOUT ME</h1>
          <p>
            Hi, I'm Stefan. Welcome to my personal website, which I built from
            scratch using React to teach myself web development. I am native to
            the Boston Area but currently working as a Software Reverse
            Engineer/Vulnerability Research Intern at Battelle in Columubus,
            Ohio. In May 2024, I graduated from UMass Amherst with a BS in
            Computer Science and am currently set to pursue a Master's degree in
            Computer Science from UMass. My expected graduation date is May
            2025. I am passionate about reverse engineering, vulnerability
            research, and exploit development: I spend much of my free time
            playing CTFs, learning new exploit techniques, and expanding my
            reverse engineering toolkit. I also love to ski, hike, and backpack
            when I get the chance. I am looking to join an organziation
            full-time as an entry level reverse engineer where I can make a
            meaningful contribution while improving my skills and fostering new
            connections. Please reach out to me through my contacts below if you
            are looking for a personable, motivated and fast-learning reverse
            engineer who is ready to make an impact.
          </p>
        </div>
      </div>
    </motion.div>
  );
};

export default About;
