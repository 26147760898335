import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Markdown from "react-markdown";
import "./Article.css";
import { duotoneSea } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";

const Article = () => {
  const { id } = useParams();
  const [content, setContent] = useState(null);
  const [articleText, setArticleText] = useState(null);
  const [error, setError] = useState(null);

  function formatDate(date) {
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}api/articles/` + id)
      .then((response) => {
        setContent(response.data.data);
        setArticleText(response.data.data.attributes.content);
      })
      .catch((error) => {
        console.error("Error fetching the article:", error);
        setError("Failed to load article.");
      });
  }, [id]);

  if (error) {
    return <div>{error}</div>;
  }

  if (articleText === null) {
    return <div>Loading...</div>;
  }

  return (
    <div className="markdown-container">
      <h1 className="title">{content.attributes.title}</h1>
      <h2 className="metadata">
        {formatDate(new Date(content.attributes.createdAt))} • Stefan Berlind
      </h2>
      <Markdown
        components={{
          code(props) {
            const { children, className, ...rest } = props;
            const match = /language-(\w+)/.exec(className || "");
            return match ? (
              <SyntaxHighlighter
                {...rest}
                PreTag="div"
                language={match[1]}
                style={duotoneSea}
              >
                {String(children).replace(/\n$/, "")}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {articleText}
      </Markdown>
    </div>
  );
};

export default Article;
